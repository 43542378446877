import { SlideNumber, configureSplideI18n } from './slide-func.js';

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const windowWidth = $(window).width();
    const relatedLength = windowWidth > 767 ? 3 : 1;
    const mobileView = windowWidth < 768;
    configureSplideI18n();

    // Inicjalizacja slidera "related-slider"
    if ($("[data-related-slider-list] > li").length > relatedLength) {
      if (!mobileView) { // Inicjalizacja na desktopie
        const sliders = $("[data-related-slider]");

        sliders.each(function (i) {
          $(this).attr("id", `slider${i}`);
          const splide = new Splide(`#slider${i}`, {
            perPage: 3,
            perMove: 1,
            pagination: false,
            gap: "20px",
            interval: 3000,
            breakpoints: {
              640: {
                perPage: 2,
              },
            },
          });

          // Dodawanie klas po zamontowaniu slidera
          splide.on('mounted', function () {
            const prevButton = splide.root.querySelector('.splide__arrow--prev');
            const nextButton = splide.root.querySelector('.splide__arrow--next');

            if (prevButton) {
              prevButton.classList.add('buttonPrev_boxSeeAlsoBelowArticle-gtm');
            }

            if (nextButton) {
              nextButton.classList.add('buttonNext_boxSeeAlsoBelowArticle-gtm');
            }
          });

          splide.mount();
        });
      } else { // Inicjalizacja na urządzeniach mobilnych
        $(".relatedBBcode [data-related-slider]").each(function (i) {
          $(this).attr("id", `slider${i}`);
          const splide = new Splide(`#slider${i}`, {
            perPage: 2,
            perMove: 2,
            pagination: false,
            gap: "20px",
            interval: 3000,
            breakpoints: {
              575: {
                perPage: 1,
                perMove: 1,
                padding: {
                  right: 80,
                },
              },
            },
          });

          // Dodawanie klas po zamontowaniu slidera
          splide.on('mounted', function () {
            const prevButton = splide.root.querySelector('.splide__arrow--prev');
            const nextButton = splide.root.querySelector('.splide__arrow--next');

            if (prevButton) {
              prevButton.classList.add('buttonPrev_boxSeeAlsoBelowArticle-gtm');
            }
    
            if (nextButton) {
              nextButton.classList.add('buttonNext_boxSeeAlsoBelowArticle-gtm');
            }
          });
    
          splide.mount();
        });
      }
    }

    // Inicjalizacja slidera "related-articles-slider" na desktopie
    if (!mobileView && $("[data-related-articles-slider-list] > li").length > relatedLength) {
      new Splide("[data-related-articles-slider]", {
        perPage: 3,
        perMove: 3,
        pagination: false,
        gap: "20px",
        interval: 3000,
        breakpoints: {
          639: {
            perPage: 2,
          },
          575: {
            perPage: 1,
            perMove: 1,
            arrows: false,
            padding: {
              right: 80,
            },
          },
        },
      }).mount();
    }

    // Inicjalizacja slidera "see-also-article-slider" na desktopie i mobilu
    if ($("[data-see-also-article-slider]").length > 0) {
      const seeAlsoLength = windowWidth > 767 ? 3 : 1;

      $("[data-see-also-article-slider]").each(function () {
        if ($(this).find("li").length > seeAlsoLength) {
          var sliderId = $(this).attr("id");
          new Splide("#" + sliderId, {
            perPage: 3,
            perMove: 3,
            pagination: false,
            gap: "60px",
            interval: 3000,
            breakpoints: {
              1199: {
                gap: "30px",
              },
              991: {
                perPage: 2,
                perMove: 2,
              },
            },
          }).mount();
        }
      });
    }
  });
}); 
